<template>
    <v-container>
        <div class="transfer-item">
            <!-- <h4 class="transfer-item-title">{{itemName}}</h4> -->
            <div class="input-item" :class="{'rq-warning': price===null && showWarning}">
              <span>金额</span>
              <input
                  type="number"
                  v-model="price"
                  placeholder="请输入金额"
              />
            </div>
            <div class="input-item" :class="{'rq-warning': studentName==='' && showWarning}">
                <span>姓名</span>
                <input
                    type="text"
                    v-model="studentName"
                    placeholder="请输入学生姓名"
                />
            </div>
            <div class="input-item" v-if="openid == undefined || openid == '' || this.idCard == ''" :class="{'rq-warning': idCard==='' && showWarning}">
                <span>用户编号</span>
                <input
                    type="text"
                    v-model="idCard"
                    placeholder="请输入身份证号码"
                />
            </div>
            <div class="input-item" :class="{'rq-warning': className==='' && showWarning}">
                <span>班级</span>
                <input v-if="onlyClass" type="text" :value="className" readonly>
                <div v-else class="select" @click="classVisible = true">{{className || '请选择班级'}}</div>
            </div>
            <div class="input-item" :class="{'rq-warning': subjectName==='' && showWarning}">
                <span>项目</span>
                <div class="select " @click="projectVisible = true">{{subjectName || '请选择项目'}}</div>
            </div>
            <h4 class="transfer-item-title" style="margin-top: 24px">付款方式</h4>
            <div>
                <v-radio-group v-model="payMethod" class="pay-method">
                    <v-radio value="1" class="pay-method-item"
                        v-if="(!onlyPayMethod || onlyPayMethod === '1')&&showThisPay(1)"

                    >
                        <template v-slot:label>
                            <i class="pay-icon ali-pay-icon"></i>支付宝支付
                        </template>
                    </v-radio>
                    <v-radio value="4" class="pay-method-item"
                             v-if="(!onlyPayMethod || onlyPayMethod === '1')&&showThisPay(3)"

                    >
                      <template v-slot:label>
                        <i class="pay-icon ali-pay-icon"></i>支付宝支付(银联商务)
                      </template>
                    </v-radio>
                    <v-radio value="2" v-if="(!onlyPayMethod || onlyPayMethod === '2')&&showThisPay(2)" >
                        <template v-slot:label>
                            <i class="pay-icon wx-pay-icon"></i>微信支付
                        </template>
                    </v-radio>
                    <v-radio value="3" v-if="(!onlyPayMethod || onlyPayMethod === '2') &&showThisPay(3)" >
                        <template v-slot:label>
                            <i class="pay-icon wx-pay-icon"></i>微信支付(银联商务)
                        </template>
                    </v-radio>

                </v-radio-group>
            </div>
            <h4 class="transfer-item-title" style="margin-top: 24px">付款人信息</h4>
            <div class="input-item" :class="{'rq-warning': userInfo.realName==='' && showWarning}">
                <span>姓名</span>
                <input
                    type="text"
                    v-model="userInfo.realName"
                    placeholder="请输入付款人姓名"
                />
            </div>
            <div class="input-item" :class="{'rq-warning': userInfo.mobile==='' && showWarning}">
                <span>手机号</span>
                <input
                    type="tel"
                    v-model="userInfo.mobile"
                    placeholder="请输入付款人手机号"
                    maxlength="11"
                />
            </div>
            <div class="input-item">
                <span>缴费人附言</span>
                <input
                    type="text"
                    maxlength="50"
                    v-model="remark"
                    placeholder="请输入缴费人附言"
                />
            </div>
            <v-btn
                class="button-submit"
                rounded
                depressed
                color="primary"
                @click="submit"
            >
                立即支付
            </v-btn>
        </div>
        <div class="class-modal" v-if="classVisible" @click.self="classVisible = false">
            <div class="class-modal-container">
                <div class="class-modal-title">选择班级</div>
                <div class="class-list">
                    <v-treeview
                        :items="classList"
                        :active="active"
                        dense
                        hoverable
                        activatable
                        item-text="organizationName"
                        selected-color="indigo"
                        item-disabled="disabled"
                        open-on-click
                        return-object
                        expand-icon="mdi-chevron-down"
                        @update:active="handleClassSelect"
                    >
                    </v-treeview>
                </div>
                <div class="class-modal-close primary" @click="classVisible = false">
                    关闭
                </div>
            </div>
        </div>
        <div class="class-modal" v-if="projectVisible" @click.self="projectVisible = false">
            <div class="class-modal-container">
                <div class="class-modal-title">选择项目</div>
                <div class="class-list">
                    <v-treeview
                        :items="projectList"
                        :active="projectActive"
                        dense
                        hoverable
                        activatable
                        item-key="uuid"
                        item-text="free_name"
                        selected-color="indigo"
                        item-disabled="disabled"
                        open-on-click
                        return-object
                        expand-icon="mdi-chevron-down"
                        @update:active="handleProjectSelect"
                    >
                    </v-treeview>
                </div>
                <div class="class-modal-close primary" @click="projectVisible = false">
                    关闭
                </div>
            </div>
        </div>
        <v-dialog
            v-model="dialog"
            max-width="280"
        >
            <v-card>
                <v-card-text class="need-pay-dialog">
                    您有一条待支付的转账订单，请先确认完善信息并支付！
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        depressed
                        @click="goPay"
                    >
                        去完善
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
const phoneReg = /^1\d{10}$/;

import {getClasses, hasOrderUnpay, getProjectListByOrg, allPayMethod} from '@/service/service';
import {getURLParame,overTime} from '@/util/util';
export default {
    name: 'TransferItem',
    data() {
        return {
            showIdCard: false,
            showWarning: false,
            itemName: '',
            itemKey: '',
            price: null,
            idCard: '',
            studentName: '',
            className: '',
            classId: '',
            classVisible: false,
            classList: [],
            projectVisible: false,
            projectList: [],
            subjectId: '',
            subjectName: '',
            payMethod: null,
            onlyPayMethod: '',
            remark: '',
            userInfo: {
                realName: '',
                mobile: ''
            },
            order_id: '',
            order_card: '',
            active: [],
            projectActive: [],
            busId: '',
            onlyClass: false,
            dialog: false,
            openid: undefined,
            patMethodList: [],
        }
    },

    computed: {
        canSubmit() {
            return !this.price || !this.studentName ||
                !this.userInfo.realName || !this.userInfo.mobile ||
                !this.classId || !this.idCard || !this.subjectId
        }
    },

    beforeMount() {
        console.log('transfer beforeMount', window.location.href)
        const param = this.$route.query;
        this.itemName = param.name;
        this.itemKey = getURLParame('organ_id');
        this.busId = getURLParame('merchant_id');

        this.getClassList();
        this.getAllPayMethod(this.itemKey);
        let userInfo = localStorage.getItem('userInfo');
        userInfo = userInfo ? JSON.parse(userInfo) : {};
        const isComplete = userInfo.tmpFlag === '1';
        if (isComplete) {
            const userInfo = localStorage.getItem('userInfo');
            this.userInfo = userInfo ? JSON.parse(userInfo) : {};
        }
        if (this.$isAli) {
            this.onlyPayMethod = this.payMethod = '1'
        }
        if (this.$isWX) {
            this.onlyPayMethod = this.payMethod = '2'
        }
        let openid = localStorage.getItem('openid')
        if (openid != undefined && openid != '') {
            this.openid = openid;
            this.idCard = openid;
        } else {
            openid = localStorage.getItem('code');
            if (openid != undefined && openid != '') {
                this.openid = openid;
                this.idCard = openid;
            }
        }
    },
    mounted() {
    },
    methods: {
        // hideIdCard() {
        //     let openid = localStorage.getItem('openid')
        //     if (openid != undefined && openid != '') {
        //         this.openid = openid;
        //         this.idCard = openid;
        //         return true;
        //     }
        //     return false;
        // },
        showThisPay(payId) {
            return this.patMethodList.filter(x=>x.methodType == payId).length >0;
        },
        getAllPayMethod(orgId) {
            allPayMethod({
                organ_id: orgId
            }).then(data=>{
                console.log('allPayMethod', data);
                this.patMethodList = data.data;
                if (this.patMethodList.length > 0) {
                    if(this.patMethodList[0].methodType == 3) {
                        if (this.$isAli) {
                            this.payMethod = '4'
                        }
                        if (this.$isWX) {
                            this.payMethod = '3'
                        }
                    }
                }
            })
        },
        getClassList() {
            getClasses(this.itemKey).then(res => {
                if (res.data.length) {
                    this.dealTree(res.data);
                    this.classList = res.data;
                } else {
                    this.className = this.itemName;
                    this.classId = this.itemKey;
                    this.getProjectList();
                    this.onlyClass = true;
                }
            }).catch(err => {
                this.$toast.error(err.msg || '请求失败');
            });
        },
        dealTree(data) {
            data.forEach(item => {
                if (item.children && item.children.length) {
                    this.dealTree(item.children);
                } else {
                    item.children === null && delete item.children;
                }
            });
        },
        handleClassSelect(val) {
            if (val.length) {
                this.active = val;
                this.classId = val[0].id;
                this.className = val[0].organizationName;
                this.classVisible = false;
                this.getProjectList();
            }
        },
        handleProjectSelect(val) {
            if (val.length) {
                this.projectActive = val;
                this.subjectId = val[0].uuid;
                this.subjectName = val[0].free_name;
                // this.price = val[0].subject_price;
                this.projectVisible = false;
            }
        },
        getProjectList2(subjectCode) {
          this.$loading.show();
          getProjectListByOrg({
            organ_id: this.classId,
          }).then(res => {
            this.projectList = res.data;
            //console.log(this.projectList)
            let selectData = this.projectList.filter(x=> x.subject_code === subjectCode);
            //console.log('selectData', selectData)
            if (selectData.length > 0) {
              this.subjectId = selectData[0]['uuid'];
              this.subjectName = selectData[0]['free_name'];
            }
            // this.projectActive = [
            //   {
            //     uuid: subjectId,
            //     free_name: subjectName,
            //     subjectId: subjectId,
            //     subjectName: subjectName
            //   }
            // ]

            // this.subjectId = subjectId;
            // this.subjectName = subjectName;
            this.showWarning = true;
            this.dialog = false;
            this.$loading.hide();
          }).catch(err => {
            this.dialog = false;
            this.$loading.hide();
            this.$toast.error(err.msg || '请求失败');
          })
        },
        getProjectList() {
            this.subjectId = '';
            this.subjectName = '';
            this.$loading.show();
            getProjectListByOrg({
              organ_id: this.classId,
            }).then(res => {
                this.projectList = res.data;
                this.$loading.hide();
            }).catch(err => {
                this.$loading.hide();
                this.$toast.error(err.msg || '请求失败');
            })
        },
        submit() {
            if (overTime()) {
                this.$toast.open('您好，23:55 ~ 00:05禁止交易，请您在其他时间缴费', 3000)
                return
            }
            let openid = localStorage.getItem('openid')
            if (openid != undefined && openid != '') {
              this.openid = openid;
              this.idCard = openid;
              this.showIdCard = false;
            } else {
                openid = localStorage.getItem('code');
                if (openid != undefined && openid != '') {
                    this.openid = openid;
                    this.idCard = openid;
                } else {
                    this.showIdCard = true;
                }
                
            }


            if (this.canSubmit) {
              this.$toast.error('请检查必填内容')
              this.showWarning = true;
              return ;
            }
            if (!this.validate()) {
                this.showWarning = true;
                return;
            }
            this.showWarning = false;
            if (this.order_id !== '' && this.order_card === this.idCard) {
              this.goTransferConfirm();
            } else {
              this.order_id = '';
              this.order_card = '';
              this.orderRepeat()
                  .then(() => {
                    this.goTransferConfirm();
                  })
            }


        },
        goTransferConfirm() {
          this.$router.push({
            name: 'transferConfirm',
            query: {
              t: this.payMethod,
              p: this.price,
              n: this.studentName,
              c: this.className,
              o: this.classId,
              pm: this.userInfo.mobile,
              pn: this.userInfo.realName,
              m: this.busId,
              id: this.idCard,
              r: this.remark,
              s: this.subjectId,
              sn: this.subjectName,
              ao: this.order_id
            }
          });
        },
        validate() {
            if (!phoneReg.test(this.userInfo.mobile)) {
                this.$toast.error('电话号码格式错误')
                return false
            }
            if (!this.payMethod) {
                this.$toast.error('请选择支付方式')
                return false
            }
            if (!this.subjectId) {
                this.$toast.error('请选择项目')
                return false
            }
            return true
        },
        orderRepeat() {
            return new Promise(resolve => {
                hasOrderUnpay({
                    idcard: this.idCard
                }).then(res => {
                    if (res.data) {
                        // res.data.type = +res.data.type + 1;
                        this.unPayOrder = res.data;
                        this.dialog = true;
                    }
                }).catch(err => {
                    if (err.code === 300) {
                      resolve()
                    } else if (err.code === 400) {
                        resolve()
                    } else if (err.code === 501) {
                        this.$toast.error(err.msg || '存在错误订单,请联系工作人员删除');
                    } else {
                        this.$toast.error(err.msg || '请求失败');
                    }
                })
            })
        },
        goPay() {
          const data = this.unPayOrder
          this.$loading.show();
          // this.payMethod = data.type;
          this.price = data.subject_price;
          this.studentName = data.name;
          this.className = data.organ_name;
          this.classId = data.organ_id;
          this.userInfo.mobile = data.parentmobile;
          this.userInfo.realName = data.payname;
          this.busId = data.merchant_id;
          // this.idCard = data.idcard;
          this.remark = data.remark;
          this.subjectId = '';
          this.subjectName = '';
          this.order_id = data.order_id;
          this.order_card = data.idcard;
          this.dialog = false;

          this.$loading.hide();
          this.getProjectList2(data.subject_code);

            // this.$router.push({
            //     name: 'transferConfirm',
            //     query: {
            //         t: data.type,
            //         p: data.subject_price,
            //         n: data.name,
            //         c: data.organ_name,
            //         o: data.organ_id,
            //         pm: data.parentmobile,
            //         pn: data.payname,
            //         m: data.merchant_id,
            //         id: this.idCard,
            //         u: true,
            //         r: data.remark,
            //         s: data.subjectId,
            //         sn: data.subjectName,
            //     }
            // });
        }
    }
}
</script>

<style lang="scss" scoped>
.transfer-item {
    padding-bottom: 24px;
}
.transfer-item-title {
    padding-bottom: 12px;
    border-bottom: 1px solid #efefef;
    margin-bottom: 16px;
}
.button-submit {
    margin-top: 32px;
}
.class-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
    background: rgba(0, 0, 0, 0.6);
    .class-modal-container {
        position: absolute;
        width: 100%;
        bottom: 0;
        animation: classModal 0.2s linear;
        background: #ffffff;
        .class-modal-title {
            height: 56px;
            line-height: 56px;
            text-align: center;
            border-bottom: 1px solid #dedede;
            font-weight: bold;
        }
        .class-modal-close {
            height: 46px;
            line-height: 46px;
            text-align: center;
            color: #ffffff;
        }
        .class-list {
            max-height: 350px;
            overflow-y: auto;
            padding: 16px 0 !important;
            li {
                width: 100%;
                height: 46px;
                padding: 0 36px;
                line-height: 46px;
                text-align: center;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                border-bottom: 1px solid #efefef;
                color: #888888;
            }
            li.active {
                color: #64b5f6;
                background: url('../../assets/images/selected.png') no-repeat;
                background-position: right center;
                background-size: 24px;
            }
        }
    }
}

.rq-warning {
  border: 3px solid #F00
}

.pay-method {
    margin-top: 0;
    padding: 0;
    & ::v-deep .v-input__control {
        .v-label {
            font-size: 14px;
            color: #666666 !important;
        }
    }
    .pay-icon {
        display: inline-block;
        width: 24px;
        height: 24px;
        margin-right: 4px;
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        &.ali-pay-icon {
            background-image: url('../../assets/images/ali_pay.png');
        }
        &.wx-pay-icon {
            background-image: url('../../assets/images/wx_pay.png');
        }
    }
    .pay-method-item {
        margin-bottom: 16px;
    }
}
.need-pay-dialog {
    padding-top: 20px !important;
}
@keyframes classModal {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0);
    }
}
</style>
